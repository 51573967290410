import { AxiosResponse } from 'axios';
import instance from './request';

// function toURLSearchParams(obj: Record<string, string | number>): string {
//   const entries = Object.entries(obj);
//   const params = entries.map(([key, value]) => `${key}=${value.toString()}`).join('&');
//   return `?${params}`;
// }

export type UserIdType = {
  sCode: string;
  gender: string;
  id: number;
  name: string;
};

export const fetchToken = <T>(data: { email: string; password: string }): Promise<AxiosResponse<T>> => {
  return instance.post('/auth/login', {
    ...data,
  });
};

const baseURL = 'https://podo-api.brightuprise.com/';

export const podoLogin = <T>(data: { password: string; username: string }): Promise<AxiosResponse<T>> => {
  return instance.post(`${baseURL}api/authentications`, {
    ...data,
    type: 'SCODE',
  });
};

// get user id
export const getUserId = <T>(): Promise<AxiosResponse<T>> => {
  return instance(`${baseURL}api/user`, { method: 'get' });
};
