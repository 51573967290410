import { useNavigate } from 'react-router-dom';
import { NavBar } from 'antd-mobile';
import styled from 'styled-components';

export const HeaderText = styled(NavBar)`
  height: 46px;
  z-index: 500;
  background-color: #ffffff;
  top: 0;
  padding: 0px;
  position: sticky;
  left: 0;
  box-shadow: 0px 7px 15px 7px #efefef;
  width: 100vw;
`;

export const PageLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 100vw;
  /* height: 100vh; */
`;

const Header = ({
  children,
  right,
  headerText,
  onBack,
  headerStyle = {},
}: {
  children: React.ReactNode;
  right: React.ReactNode;
  headerText: string;
  onBack?: () => void;
  headerStyle?: React.CSSProperties;
}): JSX.Element => {
  const navigate = useNavigate();
  return (
    <PageLayoutContainer>
      <HeaderText onBack={onBack ? onBack : () => navigate(-1)} right={right} style={{ ...headerStyle }}>
        {headerText}
      </HeaderText>
      {children}
    </PageLayoutContainer>
  );
};

export default Header;
