import styled, { keyframes } from 'styled-components';
import { Grid } from 'antd-mobile';
const GridItem = Grid.Item;

export const StyledGrid = styled(Grid)`
  width: 100%;
`;

export const StyledGridItem = styled(GridItem)`
  display: flex;
  height: 90px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    margin-bottom: 4px;
  }
  cusor: pointer;
`;

export const PageLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 100vw;
  align-items: center;
  justify-content: center;
  padding-top: 20%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  bottom: 50%;
`;

export const rings1 = keyframes`
   0% {
    transform: rotateX(65deg) rotateZ(0deg) scale(1.75);
  }

  100% {
    transform: rotateX(65deg) rotateZ(360deg) scale(1.75);
  }
`;

export const rings2 = keyframes`
  0% {
    transform: rotateX(65deg) rotateZ(0deg) scale(1.7);
  }

  100% {
    transform: rotateX(65deg) rotateZ(360deg) scale(1.7);
  }
`;

export const Planet = styled.div`
  display: block;
  width: 30px;
  height: 30px;
  position: relative;
  transform-style: preserve-3d;
  border-radius: 50%;
  background: #fcc96b;
  background: #fcc96b;
  position: absolute;
  top: 10%;
  right: 10%;
  background: linear-gradient(
    180deg,
    #fcc96b 0%,
    #fcc96b 15%,
    #f7ae01 15%,
    #f7ae01 19%,
    #fcc96b 19%,
    #fcc96b 22%,
    #f7ae01 22%,
    #f7ae01 28%,
    #fcc96b 28%,
    #fcc96b 31%,
    #fcc96b 33%,
    #fcc96b 36%,
    #f7ae01 36%,
    #f7ae01 48%,
    #fcc96b 48%,
    #fcc96b 55%,
    #f7ae01 55%,
    #f7ae01 66%,
    #fcc96b 66%,
    #fcc96b 70%,
    #f7ae01 70%,
    #f7ae01 73%,
    #fcc96b 73%,
    #fcc96b 82%,
    #f7ae01 82%,
    #c7ba9d 86%,
    #fcc96b 86%
  );
  box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.25), inset 8px -4px 6px rgba(199, 128, 0, 0.5),
    inset -8px 4px 8px rgba(255, 235, 199, 0.5), inset 20px -5px 12px #f7ae01, 0 0 100px #ffffff59;
  transform: rotateZ(-15deg);
  &::before {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 16px solid #e1a519;
    border-top-width: 0;
    border-radius: 50%;
    box-shadow: 0 -2px 0 #c18620;
    animation: ${rings1} 0.8s infinite linear;
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: 8px solid #d48b0c;
    border-top-width: 0;
    border-radius: 50%;
    box-shadow: 0 -2px 0 #b99309;
    animation: ${rings2} 0.8s infinite linear;
  }
`;

export const AppContainer = styled.div`
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
`;

export const BodyContainer = styled.div`
  /* flex: 1; */
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
`;
export const BottomContainer = styled.div`
  flex: 0;
  border-top: solid 1px var(--adm-color-border);
  position: fixed;
  bottom: 0px;
  width: 100vw;
  z-index: 9999;
  border-top: 1px solid #ccc;
  .adm-tab-bar-item-title {
    font-size: 18px;
    margin-top: 8px;
    margin-bottom: 4px;
  }
}
`;
