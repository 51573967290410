export const CONTENT_TYPES = {
  JSON: 'application/json',
  FORM: 'application/x-www-form-urlencoded',
  MULTIPART: 'multipart/form-data',
};

export const ALL_BASE_HEADERS = {
  JSON: {
    'Content-Type': CONTENT_TYPES.JSON,
  },
  FORM: {
    'Content-Type': CONTENT_TYPES.FORM,
  },
  MULTIPART: {
    'Content-Type': CONTENT_TYPES.MULTIPART,
  },
};
