import dayjs from 'dayjs';
import * as localForage from 'localforage';
import { fetchToken, podoLogin } from 'src/api/users';
import { ReturnPropsType } from 'src/utils';

export const STORAGE_KEYS = {
  AUTH: {
    USER_TOKEN: 'user-token',
    USER_REFRESH_TOKEN: 'user-refresh-token',
    USER_REFRESH_EXPIRES: 'user-token-expires',
    USER_REFRESH_EXPIRES_DATE: 'user-token-expires-date',
    USER_SCODE: 'user-scode',
    LOGIN_TIME: 'use-login-time',
  },
  PODO: {
    Scode: 'scode',
    Gender: 'gender',
    Id: 'id',
    Name: 'name',
    Pass: 'MburEkrRF1KJ5VuW9Te7iQ==',
    TOKEN: 'podo-token',
    EXPIRED: 'EXPIRED',
  },
};

export const AUTH_STORE = localForage.createInstance({
  name: 'AUTH_STORE',
});

export const PODO_STORE = localForage.createInstance({
  name: 'PODO_STORE',
});

export const STORES = {
  AUTH_STORE,
  PODO_STORE,
};

export const checkLoginState = async (): Promise<boolean> => {
  const expiredTime = await AUTH_STORE.getItem(STORAGE_KEYS.AUTH.LOGIN_TIME);
  return dayjs(Number(expiredTime) * 1000).isAfter(dayjs().subtract(1, 'hour'));
};

export const isExpired = async (type?: string): Promise<boolean> => {
  const expiredDate =
    (await type) === 'PODO'
      ? PODO_STORE.getItem(STORAGE_KEYS.PODO.EXPIRED)
      : AUTH_STORE.getItem(STORAGE_KEYS.AUTH.USER_REFRESH_EXPIRES_DATE);
  return dayjs().isAfter(dayjs(expiredDate as unknown as string));
};

export const doRefetchToken = async (isForceRefresh?: boolean): Promise<void> => {
  const shouldRefetch = await isExpired();
  if (shouldRefetch || isForceRefresh) {
    const { data } = await fetchToken<{ access_token: string; refresh_token: string; expires: number }>({
      email: 'maythe4thBWY@hotmail.com',
      password: 'S106186!',
    });
    const { expires, refresh_token, access_token } = data;
    const expiredDate = dayjs().add(expires, 'ms').format('YYYY-MM-DD HH:mm:ss');
    await AUTH_STORE.setItem(STORAGE_KEYS.AUTH.USER_REFRESH_EXPIRES_DATE, expiredDate);
    await AUTH_STORE.setItem(STORAGE_KEYS.AUTH.USER_REFRESH_TOKEN, refresh_token);
    await AUTH_STORE.setItem(STORAGE_KEYS.AUTH.USER_REFRESH_EXPIRES, expires);
    await AUTH_STORE.setItem(STORAGE_KEYS.AUTH.USER_TOKEN, access_token);
  }
};

export const doRefetchPodoToken = async (isForceRefresh?: boolean): Promise<void> => {
  const shouldRefetch = await isExpired('PODO');
  if (shouldRefetch || isForceRefresh) {
    try {
      const username = await PODO_STORE.getItem(STORAGE_KEYS.PODO.Scode);
      const password = await PODO_STORE.getItem(STORAGE_KEYS.PODO.Pass);
      const result: { data: ReturnPropsType } = await podoLogin({
        username: (username as string) || '',
        password: (password as string) || '',
      });
      if (result && result.data) {
        const mainAppData = {
          token: '',
          expired: -1,
        };
        const data = Object.values(result.data || {});
        Object.values(data).map((value) => {
          if (typeof value === 'number') {
            mainAppData.expired = value;
          }
          if (typeof value === 'string' && value.length > 10) {
            mainAppData.token = value;
          }
        });
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Scode, username);
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Pass, password);
        PODO_STORE.setItem(STORAGE_KEYS.PODO.EXPIRED, mainAppData.expired);
        PODO_STORE.setItem(STORAGE_KEYS.PODO.TOKEN, `Bearer ${mainAppData.token}`);
      }
    } catch (error) {
      console.log('JSON.stringify(error): ', JSON.stringify(error || '登录失败，请重试'));
    }
  }
};
