import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0,
  left: 0,
`;

export const FormContainer = styled.div`
  width: 60vw;
  max-width: 500px;
  z-index: 100;

  /* 针对手机屏幕的特殊处理 */
  @media (max-width: 500px) {
    /* 宽度最大为 300px */
    max-width: 300px;
  }

  /* 针对非手机屏幕且宽度大于 500px 的情况 */
  @media (min-width: 500px) and (max-width: 1024px) {
    /* 宽度最大为 500px */
    max-width: 500px;
  }
  display: flex;

  flex-direction: column;
  gap: 10px;
  background-color: white;
  padding: 2.5em;
  border-radius: 25px;
  transform: translateX(-0.5em) translateY(-0.5em);
  border: 1px solid #171717;
  box-shadow: 10px 10px 0px #365c7d;
`;
