import { allRoutePath } from 'src/routes';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContainer, BodyContainer, BottomContainer, StyledGrid, StyledGridItem } from './index.style';
import { useContext, useEffect, useState } from 'react';
import { TabBar } from 'antd-mobile';
import {
  AppOutline,
  CalendarOutline,
  TextOutline,
  // ClockCircleOutline,
  EditSOutline,
  HistogramOutline,
  MailOpenOutline,
  MailOutline,
  PhonebookOutline,
  SearchOutline,
  HeartFill,
  TeamOutline,
  MovieOutline,
  UserOutline,
  FlagOutline,
  BankcardOutline,
  ClockCircleFill,
} from 'antd-mobile-icons';
import { HeaderText } from 'src/components/Header';
import LoginPage from 'src/pages/LoginPage/Index';
import { MainAppDataContext } from 'src/context/MainAppDataContext';
import { AUTH_STORE, PODO_STORE, STORAGE_KEYS } from 'src/store';
import SunClound from 'src/components/SunClound';

const iconFontSize = 26;
const tabs = [
  {
    key: 'INDEX',
    title: '我是向导',
    icon: <AppOutline fontSize={24} />,
  },
  {
    key: 'SUB',
    title: '我是个教',
    icon: <CalendarOutline fontSize={24} />,
  },
];

const IndexTab = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <BodyContainer>
      {/* <Planet /> */}
      <StyledGrid
        columns={3}
        gap={8}
        style={{
          width: '100%',
          fontSize: '18px',
        }}
      >
        <StyledGridItem onClick={() => navigate(allRoutePath.blockListCheck)}>
          <TeamOutline fontSize={iconFontSize} />
          <div>查重查黑</div>
        </StyledGridItem>
        <StyledGridItem
          onClick={() => navigate(allRoutePath.fruitDetail, { state: { mode: 'create', scodeDisabled: true } })}
        >
          <PhonebookOutline fontSize={iconFontSize} />
          <div>21项录入</div>
        </StyledGridItem>
        <StyledGridItem
          onClick={() => navigate(allRoutePath.fruitDetail, { state: { mode: 'create', scodeDisabled: false } })}
        >
          <EditSOutline fontSize={iconFontSize} />
          <div>为他人填写21项</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.newPriestSearch)}>
          <SearchOutline fontSize={iconFontSize} />
          <div>MS 查重</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.priestList)}>
          <UserOutline fontSize={iconFontSize} />
          <div>MS 录入</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.fruits)}>
          <HeartFill fontSize={iconFontSize} color="#019650" />
          <div>我的果子</div>
        </StyledGridItem>
        <StyledGridItem>
          <BankcardOutline fontSize={iconFontSize} />
          <div onClick={() => navigate(allRoutePath.studentInfoList, { state: { mode: 'view' } })}>查看信卡</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.klassApplyList)}>
          <FlagOutline fontSize={iconFontSize} />
          <div>报名大班</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.tutorApplyList)}>
          <TextOutline fontSize={iconFontSize} />
          <div>申请个教接单</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.standbyApplyList)}>
          {/* <ClockCircleOutline color="#019650" fontSize={iconFontSize} /> */}
          <ClockCircleFill color="#019650" fontSize={iconFontSize} />
          <div>申请果子待教</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.ApplicationsSent)}>
          <MailOutline fontSize={iconFontSize} />
          <div>发出的ydr申请</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.IncomingApplications)}>
          <MailOpenOutline fontSize={iconFontSize} />
          <div>收到的ydr申请</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.summaryData)}>
          <HistogramOutline fontSize={iconFontSize} />
          <div>我的做工数据</div>
        </StyledGridItem>
      </StyledGrid>
    </BodyContainer>
  );
};

const SubTab = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <BodyContainer>
      {/* <Planet /> */}
      <StyledGrid
        columns={3}
        gap={8}
        style={{
          width: '100%',
          fontSize: '18px',
        }}
      >
        <StyledGridItem>
          <PhonebookOutline fontSize={iconFontSize} />
          <div onClick={() => navigate(allRoutePath.studentInfoList, { state: { mode: 'teacher' } })}>信卡填写</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.weeklyReportList)}>
          <TextOutline fontSize={iconFontSize} />
          <div>周报填写</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.tutorApplyReviewList)}>
          <MovieOutline fontSize={iconFontSize} />
          <div>个教记录</div>
        </StyledGridItem>
        <StyledGridItem onClick={() => navigate(allRoutePath.standbyReviewList)}>
          {/* <ClockCircleOutline fontSize={iconFontSize} /> */}
          <ClockCircleFill color="#019650" fontSize={iconFontSize} />
          <div>待教列表</div>
        </StyledGridItem>
      </StyledGrid>
    </BodyContainer>
  );
};

const Index = (): JSX.Element => {
  const { userInfo, setMainAppData } = useContext(MainAppDataContext);
  const { state } = useLocation() as unknown as {
    state: {
      activeTab?: string;
    };
  };
  const [activeTab, setActiveTab] = useState(state?.activeTab || 'INDEX');
  const setRouteActive = (value: string) => {
    setActiveTab(value);
  };
  useEffect(() => {
    async function init() {
      const LOGIN_TIME = await AUTH_STORE.getItem(STORAGE_KEYS.AUTH.LOGIN_TIME);
      if (!LOGIN_TIME && setMainAppData) {
        setMainAppData({
          userInfo: {
            sCode: '',
            gender: '',
            id: -1,
            name: '',
          },
        });
        setActiveTab('INDEX');
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Scode, '');
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Gender, '');
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Id, '-1');
        PODO_STORE.setItem(STORAGE_KEYS.PODO.Name, '');
      }
      // PODO_STORE.setItem(STORAGE_KEYS.AUTH.LOGIN_TIME, Date.now() / 1000);
    }
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onBack = () => {
    if (setMainAppData) {
      setMainAppData({
        userInfo: {
          sCode: '',
          gender: '',
          id: -1,
          name: '',
        },
      });
      setActiveTab('INDEX');
      PODO_STORE.setItem(STORAGE_KEYS.PODO.Scode, '');
      PODO_STORE.setItem(STORAGE_KEYS.PODO.Gender, '');
      PODO_STORE.setItem(STORAGE_KEYS.PODO.Id, '-1');
      PODO_STORE.setItem(STORAGE_KEYS.PODO.Name, '');
    }
  };
  // GET_CLASS_LIST
  return userInfo?.sCode ? (
    <AppContainer>
      <HeaderText back="退出登录" onBack={onBack}>
        {activeTab === 'INDEX' ? '引导人角色菜单' : '教师角色菜单'}
      </HeaderText>
      <BodyContainer>
        <SunClound />
        {activeTab === 'INDEX' && <IndexTab />}
        {activeTab === 'SUB' && <SubTab />}
        <BottomContainer>
          <TabBar activeKey={activeTab} onChange={(value: string) => setRouteActive(value)} safeArea>
            {tabs.map((item) => (
              <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
          </TabBar>
        </BottomContainer>
      </BodyContainer>
    </AppContainer>
  ) : (
    <LoginPage />
  );
};

export default Index;
