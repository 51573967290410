export const WGreenBlue = (): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={1508}
    height={1294}
    preserveAspectRatio="xMidYMid"
    style={{
      margin: 'auto',
      background: '#f1f2f3',
      display: 'block',
      zIndex: 1,
      width: '100vw',
      height: '100vh',
      position: 'absolute',
    }}
  >
    <g>
      <defs>
        <linearGradient id="a" x1={0} x2={0} y1={0} y2={1}>
          <stop offset={0} stopColor="#3184ce" />
          <stop offset={1} stopColor="#e9f6ff" />
        </linearGradient>
      </defs>
      <path fill="url(#a)" d="M0 0h1508v1250H0z" />
      <circle cy={813.714} r={53.572} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={53.572} cy={808.19} r={62.624} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.045454545454545456s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={116.196} cy={820.135} r={57.006} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.09090909090909091s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={173.202} cy={800.925} r={83.384} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.13636363636363635s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={256.586} cy={835.37} r={53.85} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.18181818181818182s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={310.436} cy={839.821} r={54.083} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.2272727272727273s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={364.519} cy={827.999} r={80.664} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.2727272727272727s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={445.183} cy={838.645} r={80.073} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.3181818181818182s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={525.256} cy={845.87} r={88.257} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.36363636363636365s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={613.513} cy={859.522} r={82.642} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.4090909090909091s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={696.156} cy={871.403} r={71.659} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.4545454545454546s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={767.815} cy={876.999} r={45.547} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.5000000000000001s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={813.362} cy={873.75} r={44.982} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.5454545454545454s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={858.344} cy={872.027} r={35.969} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.5909090909090909s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={894.313} cy={870.963} r={31.513} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.6363636363636364s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={925.826} cy={856.649} r={78.076} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.6818181818181819s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1003.901} cy={855.079} r={57.63} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.7272727272727273s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1061.531} cy={847.462} r={62.394} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.7727272727272728s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1123.925} cy={831.701} r={78.641} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.8181818181818182s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1202.567} cy={852.188} r={36.156} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.8636363636363638s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1238.723} cy={830.36} r={61.755} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.9090909090909092s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1300.477} cy={842.49} r={40.829} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-0.9545454545454547s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1341.306} cy={795.424} r={88.345} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-1.0000000000000002s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1429.651} cy={839.635} r={35.678} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-1.0454545454545456s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1465.329} cy={820.253} r={51.01} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-1.0909090909090908s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1516.339} cy={784.761} r={76.849} fill="#91cbf0">
        <animateTransform
          attributeName="transform"
          begin="-1.1363636363636365s"
          calcMode="spline"
          dur="4.545454545454546s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cy={834.319} r={88.067} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={88.067} cy={871.09} r={65.019} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.045454545454545456s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={153.087} cy={905.994} r={35.557} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.09090909090909091s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={188.644} cy={888.388} r={57.361} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.13636363636363635s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={246.005} cy={877.498} r={77.308} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.18181818181818182s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={323.314} cy={909.114} r={45.06} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.2272727272727273s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={368.373} cy={920.201} r={32.26} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.2727272727272727s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={400.633} cy={901.512} r={68.438} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.3181818181818182s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={469.071} cy={918.516} r={47.377} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.36363636363636365s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={516.448} cy={921.111} r={49.963} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.4090909090909091s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={566.41} cy={913.435} r={88.981} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.4545454545454546s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={655.391} cy={927.132} r={81.995} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.5000000000000001s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={737.387} cy={937.608} r={90.461} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.5454545454545454s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={827.848} cy={931.079} r={75.9} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.5909090909090909s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={903.749} cy={930.096} r={41.558} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.6363636363636364s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={945.307} cy={921.083} r={62.131} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.6818181818181819s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1007.437} cy={910.03} r={74.302} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.7272727272727273s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1081.74} cy={914.679} r={48.544} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.7727272727272728s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1130.284} cy={895.06} r={75.043} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.8181818181818182s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1205.327} cy={877.828} r={86.555} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.8636363636363638s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1291.882} cy={891.022} r={57.214} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.9090909090909092s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1349.096} cy={854.587} r={90.183} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-0.9545454545454547s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1439.279} cy={851.372} r={81.263} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-1.0000000000000002s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1520.542} cy={848.169} r={75.274} fill="#a8d1f5">
        <animateTransform
          attributeName="transform"
          begin="-1.0454545454545456s"
          calcMode="spline"
          dur="2.272727272727273s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cy={948.976} r={44.187} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={44.187} cy={961.859} r={35.533} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.045454545454545456s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={79.72} cy={959.696} r={39.421} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.09090909090909091s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={119.141} cy={927.562} r={73.673} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.13636363636363635s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={192.814} cy={960.112} r={46.9} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.18181818181818182s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={239.714} cy={966.554} r={43.307} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.2272727272727273s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={283.021} cy={937.961} r={85.435} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.2727272727272727s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={368.456} cy={971.427} r={49.826} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.3181818181818182s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={418.282} cy={952.323} r={92.975} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.36363636363636365s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={511.257} cy={972.642} r={75.992} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.4090909090909091s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={587.249} cy={983.077} r={71.302} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.4545454545454546s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={658.551} cy={997.175} r={31.764} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.5000000000000001s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={690.315} cy={997.52} r={44.202} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.5454545454545454s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={734.517} cy={999.719} r={73.557} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.5909090909090909s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={808.074} cy={994.1} r={91.796} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.6363636363636364s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={899.871} cy={991.506} r={45.202} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.6818181818181819s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={945.072} cy={978.139} r={78.464} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.7272727272727273s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1023.537} cy={969.887} r={74.86} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.7727272727272728s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1098.396} cy={960.453} r={75.8} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.8181818181818182s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1174.196} cy={979.653} r={33.417} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.8636363636363638s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1207.613} cy={961.334} r={56.33} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.9090909090909092s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1263.943} cy={937.188} r={79.859} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-0.9545454545454547s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1343.802} cy={920.929} r={86.367} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-1.0000000000000002s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1430.169} cy={925.422} r={71.161} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-1.0454545454545456s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1501.33} cy={941.889} r={50.54} fill="#bdd7f8">
        <animateTransform
          attributeName="transform"
          begin="-1.0909090909090908s"
          calcMode="spline"
          dur="1.5151515151515154s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cy={984.694} r={66.088} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={66.088} cy={1021.271} r={39.028} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.045454545454545456s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={105.116} cy={977.831} r={83.44} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.09090909090909091s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={188.556} cy={1030.932} r={36.746} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.13636363636363635s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={225.302} cy={1036.792} r={32.336} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.18181818181818182s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={257.638} cy={1036.614} r={34.668} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.2272727272727273s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={292.305} cy={1011.49} r={71.462} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.2727272727272727s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={363.767} cy={1032.17} r={51.251} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.3181818181818182s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={415.018} cy={1045.515} r={34.264} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.36363636363636365s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={449.282} cy={1024.668} r={81.102} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.4090909090909091s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={530.385} cy={1047.543} r={46.243} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.4545454545454546s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={576.628} cy={1042.394} r={76.539} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.5000000000000001s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={653.167} cy={1053.922} r={62.8} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.5454545454545454s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={715.968} cy={1059.166} r={79.863} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.5909090909090909s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={795.831} cy={1059.358} r={69.723} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.6363636363636364s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={865.554} cy={1055.018} r={50.59} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.6818181818181819s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={916.145} cy={1050.056} r={54.036} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.7272727272727273s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={970.18} cy={1036.514} r={79.888} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.7727272727272728s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1050.069} cy={1023.177} r={86.638} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.8181818181818182s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1136.706} cy={1025.296} r={63.546} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.8636363636363638s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1200.252} cy={1024.614} r={55.457} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.9090909090909092s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1255.708} cy={1026.224} r={47.31} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-0.9545454545454547s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1303.019} cy={984.472} r={91.018} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-1.0000000000000002s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1394.036} cy={982.418} r={80.09} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-1.0454545454545456s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1474.127} cy={1008.79} r={48.172} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-1.0909090909090908s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1522.299} cy={957.424} r={87.161} fill="#cfddfa">
        <animateTransform
          attributeName="transform"
          begin="-1.1363636363636365s"
          calcMode="spline"
          dur="1.1363636363636365s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cy={1024.809} r={84.326} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={84.326} cy={1076.46} r={46.482} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.045454545454545456s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={130.808} cy={1048.603} r={78.036} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.09090909090909091s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={208.843} cy={1077.569} r={55.82} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.13636363636363635s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={264.663} cy={1081.535} r={57.095} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.18181818181818182s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={321.759} cy={1063.681} r={90.591} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.2272727272727273s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={412.35} cy={1088.191} r={69.536} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.2727272727272727s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={481.886} cy={1101.963} r={55.503} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.3181818181818182s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={537.388} cy={1103.23} r={66.051} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.36363636363636365s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={603.439} cy={1110.922} r={62.926} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.4090909090909091s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={666.365} cy={1118.843} r={51.318} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.4545454545454546s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={717.683} cy={1121.395} r={79.675} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.5000000000000001s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={797.359} cy={1121.907} r={59.313} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.5454545454545454s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={856.671} cy={1120.202} r={35.483} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.5909090909090909s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={892.154} cy={1119.154} r={31.137} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.6363636363636364s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={923.291} cy={1117.204} r={32.647} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.6818181818181819s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={955.938} cy={1105.654} r={63.307} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.7272727272727273s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1019.245} cy={1111.783} r={33.678} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.7727272727272728s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1052.923} cy={1097.131} r={60.683} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.8181818181818182s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1113.605} cy={1074.258} r={90.408} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.8636363636363638s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1204.013} cy={1100.396} r={35.75} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.9090909090909092s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1239.763} cy={1077.01} r={63.369} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-0.9545454545454547s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1303.132} cy={1071.462} r={62.404} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-1.0000000000000002s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1365.536} cy={1039.51} r={88.865} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-1.0454545454545456s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1454.401} cy={1072.834} r={47.696} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-1.0909090909090908s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1502.097} cy={1078.109} r={40.224} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-1.1363636363636365s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1542.321} cy={1072.295} r={42.806} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-1.1818181818181819s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1585.127} cy={1084.78} r={31.162} fill="#dfe5fb">
        <animateTransform
          attributeName="transform"
          begin="-1.2272727272727273s"
          calcMode="spline"
          dur="0.9090909090909092s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cy={1105.151} r={69.041} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={69.041} cy={1112.77} r={69.01} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.045454545454545456s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={138.051} cy={1132.954} r={56.152} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.09090909090909091s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={194.204} cy={1112.541} r={84.698} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.13636363636363635s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={278.902} cy={1123.468} r={85.346} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.18181818181818182s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={364.248} cy={1142.942} r={72.639} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.2272727272727273s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={436.887} cy={1160.381} r={54.724} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.2727272727272727s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={491.611} cy={1152.635} r={84.688} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.3181818181818182s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={576.299} cy={1162.323} r={90.79} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.36363636363636365s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={667.089} cy={1182.737} r={38.053} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.4090909090909091s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={705.142} cy={1183.338} r={59.954} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.4545454545454546s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={765.096} cy={1186.645} r={76.733} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.5000000000000001s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={841.829} cy={1175.421} r={89.991} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.5454545454545454s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={931.821} cy={1173.596} r={50.896} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.5909090909090909s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={982.716} cy={1172.769} r={41.843} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.6363636363636364s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1024.56} cy={1151.094} r={85.707} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.6818181818181819s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1110.267} cy={1137.182} r={89.625} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.7272727272727273s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1199.892} cy={1141.103} r={66.085} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.7727272727272728s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1265.977} cy={1130.986} r={69.972} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.8181818181818182s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1335.949} cy={1133.543} r={58.797} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.8636363636363638s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1394.746} cy={1095.338} r={90.867} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.9090909090909092s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1485.613} cy={1103.061} r={72.948} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-0.9545454545454547s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1558.561} cy={1136.284} r={40.388} fill="#ecedfc">
        <animateTransform
          attributeName="transform"
          begin="-1.0000000000000002s"
          calcMode="spline"
          dur="0.7575757575757577s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cy={1155.582} r={78.682} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={78.682} cy={1190.56} r={55.304} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.045454545454545456s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={133.986} cy={1186.984} r={63.862} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.09090909090909091s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={197.848} cy={1199.793} r={56.723} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.13636363636363635s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={254.571} cy={1222.713} r={34.33} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.18181818181818182s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={288.902} cy={1224.806} r={34.037} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.2272727272727273s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={322.939} cy={1217.551} r={47.3} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.2727272727272727s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={370.238} cy={1211.337} r={63.304} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.3181818181818182s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={433.542} cy={1215.139} r={68.354} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.36363636363636365s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={501.895} cy={1235.67} r={35.715} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.4090909090909091s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={537.61} cy={1235.345} r={42.553} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.4545454545454546s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={580.163} cy={1230.526} r={70.39} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.5000000000000001s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={650.553} cy={1237.345} r={76.867} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.5454545454545454s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={727.42} cy={1246.901} r={73.251} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.5909090909090909s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={800.671} cy={1246.136} r={52.027} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.6363636363636364s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={852.698} cy={1236.038} r={88.886} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.6818181818181819s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={941.584} cy={1235.781} r={47.628} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.7272727272727273s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={989.212} cy={1222.4} r={73.729} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.7727272727272728s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1062.942} cy={1234.498} r={31.528} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.8181818181818182s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1094.47} cy={1215.708} r={63.285} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.8636363636363638s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1157.755} cy={1211.806} r={59.438} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.9090909090909092s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1217.193} cy={1184.579} r={88.746} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-0.9545454545454547s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1305.939} cy={1183.484} r={75.723} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-1.0000000000000002s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1381.661} cy={1180.969} r={69.105} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-1.0454545454545456s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1450.767} cy={1154.497} r={86.123} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-1.0909090909090908s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1536.889} cy={1135.714} r={91.724} fill="#f7f5fd">
        <animateTransform
          attributeName="transform"
          begin="-1.1363636363636365s"
          calcMode="spline"
          dur="0.6493506493506495s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cy={1227.196} r={70.67} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="0s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={70.67} cy={1261.036} r={46.862} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.045454545454545456s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={117.533} cy={1238.682} r={72.381} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.09090909090909091s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={189.914} cy={1284.087} r={31.092} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.13636363636363635s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={221.006} cy={1246.962} r={76.672} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.18181818181818182s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={297.678} cy={1284.994} r={37.185} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.2272727272727273s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={334.863} cy={1271.491} r={60.728} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.2727272727272727s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={395.591} cy={1266.319} r={80.084} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.3181818181818182s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={475.675} cy={1296.728} r={34.478} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.36363636363636365s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={510.152} cy={1296.182} r={40.759} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.4090909090909091s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={550.911} cy={1297.892} r={43.648} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.4545454545454546s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={594.559} cy={1301.014} r={43.294} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.5000000000000001s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={637.853} cy={1302.393} r={51.97} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.5454545454545454s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={689.823} cy={1303.399} r={84.213} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.5909090909090909s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={774.036} cy={1310.082} r={60.152} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.6363636363636364s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={834.188} cy={1305.751} r={48.966} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.6818181818181819s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={883.153} cy={1294.003} r={87.558} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.7272727272727273s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={970.711} cy={1284.978} r={78.347} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.7727272727272728s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1049.059} cy={1283.023} r={61.706} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.8181818181818182s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1110.765} cy={1279.933} r={56.477} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.8636363636363638s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1167.242} cy={1272.341} r={60.302} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.9090909090909092s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1227.544} cy={1250.912} r={81.056} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-0.9545454545454547s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1308.599} cy={1248.373} r={72.086} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-1.0000000000000002s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1380.686} cy={1243.025} r={69.156} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-1.0454545454545456s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1449.842} cy={1210.676} r={91.494} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-1.0909090909090908s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
      <circle cx={1541.336} cy={1216.109} r={76.526} fill="#fff">
        <animateTransform
          attributeName="transform"
          begin="-1.1363636363636365s"
          calcMode="spline"
          dur="0.5681818181818182s"
          keySplines="0.5 0 0.5 1;0.5 0 0.5 1"
          keyTimes="0;0.5;1"
          repeatCount="indefinite"
          type="translate"
          values="0 0;-10 0;0 0"
        />
      </circle>
    </g>
  </svg>
);
