import { Button, Form, Input, Toast } from 'antd-mobile';
import { Container, FormContainer } from './index.style';
import { MainAppDataContext } from 'src/context/MainAppDataContext';
import { useContext, useEffect, useState } from 'react';
import { AUTH_STORE, PODO_STORE, STORAGE_KEYS, doRefetchToken } from 'src/store';
import { podoLogin, getUserId } from 'src/api/users';
import { ReturnPropsType } from 'src/utils';
import { WGreenBlue } from 'src/components/SvgIcon';

export type FormDataType = {
  sCode: string;
  password: string;
};

const Index = (): JSX.Element => {
  const { setMainAppData } = useContext(MainAppDataContext);
  const [refetch, setRefetch] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (refetch) {
      doRefetchToken();
    }
  }, [refetch]);
  const onFinish = async (values: FormDataType) => {
    if (setMainAppData) {
      setLoading(true);
      try {
        const result: { data: ReturnPropsType } = await podoLogin({
          username: values.sCode.toLowerCase(),
          password: values.password,
        });
        if (result && result.data) {
          const mainAppData = {
            token: '',
            expired: -1,
          };
          const data = Object.values(result.data || {});
          Object.values(data).map((value) => {
            if (typeof value === 'number') {
              mainAppData.expired = value;
            }
            if (typeof value === 'string' && value.length > 10) {
              mainAppData.token = value;
            }
          });
          setMainAppData({
            userInfo: {
              sCode: values?.sCode.toLowerCase(),
              gender: '',
              id: -1,
              name: '',
            },
            ...mainAppData,
          });
          PODO_STORE.setItem(STORAGE_KEYS.PODO.Scode, values?.sCode.toLowerCase());
          PODO_STORE.setItem(STORAGE_KEYS.PODO.Pass, values?.password);
          PODO_STORE.setItem(STORAGE_KEYS.PODO.EXPIRED, mainAppData.expired);
          PODO_STORE.setItem(STORAGE_KEYS.PODO.TOKEN, `Bearer ${mainAppData.token}`);
          AUTH_STORE.setItem(STORAGE_KEYS.AUTH.LOGIN_TIME, Date.now() / 1000);

          // 获取用户id
          const userInfoData = await getUserId();
          // console.log('user info result: ', userInfoData);
          if (userInfoData && userInfoData.data) {
            const info: any = userInfoData.data;
            setMainAppData({
              userInfo: {
                sCode: info.sCode,
                gender: info.gender,
                id: info.id,
                name: info.name,
              },
              ...mainAppData,
            });
            PODO_STORE.setItem(STORAGE_KEYS.PODO.Scode, info.sCode);
            PODO_STORE.setItem(STORAGE_KEYS.PODO.Gender, info.gender);
            PODO_STORE.setItem(STORAGE_KEYS.PODO.Id, info.id);
            PODO_STORE.setItem(STORAGE_KEYS.PODO.Name, info.name);
          }
          setRefetch(true);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Toast.show({
          content: JSON.stringify('登录失败，请重试'),
        });
        console.log('JSON.stringify(error): ', JSON.stringify(error || '登录失败，请重试'));
      }
    }
  };
  return (
    <Container>
      <WGreenBlue />
      <FormContainer>
        <Form
          requiredMarkStyle="text-optional"
          name="form"
          onFinish={onFinish}
          footer={
            <Button block type="submit" size="large" loadingText="登录中" loading={loading}>
              登录
            </Button>
          }
          mode="card"
        >
          <Form.Header>
            <img src="/statics/grapes.gif" alt="Logo" style={{ width: '50px' }} />
          </Form.Header>
          <Form.Item name="sCode" label="用户名" rules={[{ required: true }]}>
            <Input placeholder="请输入用户名" />
          </Form.Item>
          <Form.Item name="password" label="密码" rules={[{ required: true }]}>
            <Input placeholder="请输入密码" type="password" />
          </Form.Item>
        </Form>
      </FormContainer>
    </Container>
  );
};

export default Index;
