import { createContext } from 'react';

export const defaultUserValue = {};

export type MainAppDataContextType = {
  userInfo?: {
    gender: string;
    id: number;
    name: string;
    sCode: string;
  };
  token?: string;
  expired?: number;
  path?: string;
};

export type MainAppDataType = {
  setMainAppData?: React.Dispatch<React.SetStateAction<MainAppDataContextType>>;
  userInfo?: {
    gender: string;
    id: number;
    name: string;
    sCode: string;
  };
  token?: string;
  expired?: number;
  path?: string;
};

export const MainAppDataContext = createContext<MainAppDataType>(defaultUserValue);
