import { Modal, Toast } from 'antd-mobile';

import { ApolloClient, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { relayStylePagination } from '@apollo/client/utilities';
import { getHeader } from 'src/api/directus.config';
import { setContext } from '@apollo/client/link/context';
import { AUTH_STORE, STORAGE_KEYS, checkLoginState, PODO_STORE } from 'src/store';

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message }) => {
      return Toast.show({
        content: `GraphQL Error: ${message}`,
      });
    });
  }
  if (networkError) {
    Toast.show({
      content: 'Network Error',
    });
  }
});

const timestampInterceptor = setContext(async (_, { headers }) => {
  const expiredTime = await AUTH_STORE.getItem(STORAGE_KEYS.AUTH.LOGIN_TIME);
  const state = await checkLoginState();
  if (!expiredTime || !state) {
    Modal.alert({
      content: '操作时间超过1小时,请重新登录',
      onConfirm: () => {
        PODO_STORE.setItem(STORAGE_KEYS.AUTH.LOGIN_TIME, '');
        window.location.replace('/relogin');
      },
    });
  }
  const appendHeaders = getHeader();
  return {
    headers: {
      ...headers,
      ...appendHeaders,
    },
  };
});

const getHttpLink = (endpoint: string) => {
  const defaultHeaders = {
    Authorization: 'Bearer f6c8325a-89ff-4cce-89e2-72719a726399-0991a1dc-65e9-4cd1-92e5-fc416cf23a75',
  };
  return new HttpLink({
    uri: endpoint,
    headers: defaultHeaders,
  });
};

export function getGraphQLClient(endpoint: string): ApolloClient<NormalizedCacheObject> {
  return new ApolloClient({
    link: from([timestampInterceptor, errorLink, getHttpLink(endpoint)]),
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            resourceConnection: relayStylePagination(),
            tagConnection: relayStylePagination(),
          },
        },
      },
    }),
    defaultOptions: {
      query: {
        fetchPolicy: 'cache-first',
        errorPolicy: 'all',
      },
      mutate: {
        errorPolicy: 'all',
      },
    },
  });
}
