import { SHA1 } from 'crypto-js';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';

export type checkSumHeaderType = {
  CheckSum: string;
  AppKey: string;
  Nonce: string;
  CurTime: number;
};

export const getHeader = (): checkSumHeaderType => {
  const Nonce = uuidv4();
  const CheckSum = SHA1('092b78f3-c22a-48cb-9682-2ae3bf3b93f8' + Nonce + dayjs().unix()).toString();
  return {
    CheckSum,
    Nonce,
    AppKey: 'podo-new',
    CurTime: dayjs().unix(),
  };
};
